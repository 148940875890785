(function($){
  var dirRtlFlag = false;
  if ($("html").attr('dir') == 'rtl') {
    dirRtlFlag = true;
  }

  function isMobile() {
		return $(window).innerWidth() <= 1024;
  }
  
  $('.player__volume-btn').click(function(){
    if($(this).hasClass('player__volume-btn_off')){
      $(this).removeClass('player__volume-btn_off');
      $(this).find('.fa-volume-off').removeClass('fa-volume-off').addClass('fa-volume-up');
      $('#slide_1 .video-play').YTPUnmute();
    } else {
      $(this).addClass('player__volume-btn_off');
      $(this).find('.fa-volume-up').removeClass('fa-volume-up').addClass('fa-volume-off');
      $('#slide_1 .video-play').YTPMute();
    }
  });
	
    
  $('#hp-banners').on('init', function(){
    if($("#slide_1").data("type") == 'video'){
      $("#slide_1").attr('data-videoready',1);
      $("#slide_1 .video-play").YTPlayer();
      if (isMobile()) {
        var wpBanner = $('.video-play').closest('.wrapper-banners');
        wpBanner.css({'height':'320px'});
        jQuery('.video-play').on("YTPReady",function(e){
          var wpBanner = $(this).closest('.wrapper-banners');
          wpBanner.css({'height':'auto'});
        });
      }
      jQuery('#slide_1 .video-play').on("YTPEnd",function(e){
         $('#hp-banners').slick('slickNext');
      });
    }else{
      setTimeout(function(){$("#hp-banners").slick('slickPlay');},100);
      // $('#hp-banners').slick('slickNext');
    }
  });
  $('#hp-banners').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    doSlide(currentSlide, nextSlide)
  });

  function doSlide(currentSlide, nextSlide){

    $("#hp-banners").slick('slickPause');
    currentSlide += 1;
    nextSlide += 1;
    if($("#hp-banners .banner-item:nth-child("+currentSlide+")").data("type") == 'video'){
      $("#slide_"+currentSlide+" .video-play").YTPStop();
    }
    if($("#hp-banners .banner-item:nth-child("+nextSlide+")").data("type") == 'video'){
      if($("#slide_"+nextSlide).attr('data-videoready') == 1){
        $("#slide_"+nextSlide+" .video-play").YTPPlay();
      }else{
        $("#slide_"+nextSlide).attr('data-videoready',1);
        $("#slide_"+nextSlide+" .video-play").YTPlayer();
        jQuery('#slide_'+nextSlide+' .video-play').on("YTPEnd",function(e){
           $('#hp-banners').slick('slickNext');
        });
      }
    }else{
      $("#hp-banners").slick('slickPlay');
    }
  }

  $('#hp-banners').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    rtl: dirRtlFlag,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    adaptiveHeight: true,
    pauseOnHover: false
  });

  $(".volume-control").click(function(){
    var index = $(this).data("slider");
    if($(this).hasClass('volume-on')){
      $(this).removeClass('volume-on').addClass('volume-off');
      $("#slide_"+index+" .video-play").YTPMute();

    }else{
      $(this).removeClass('volume-off').addClass('volume-on');
      $("#slide_"+index+" .video-play").YTPUnmute();
    }
  });
})($);