$(document).ready(function(){
  $('.order-datetimepicker').datetimepicker({
    locale: 'he',
    icons: {
      time: 'fal fa-clock',
      date: 'fal fa-calendar-alt',
      up: 'fas fa-chevron-up',
      down: 'fas fa-chevron-down',
      previous: 'fas fa-chevron-right',
      next: 'fas fa-chevron-left',
      today: 'fal fa-camera',
      clear: 'fal fa-trash',
      close: 'fal fa-trash'
    },
    useCurrent: true,
    format: 'DD/MM/YYYY'
  });
  $('.order-datetimepicker').on('dp.change', function(e){
    console.log(1);
     var formatedValue = e.date.format("YYYY-MM-DD HH:mm:ss");
     var forInput = $("input[name='"+$(this).data('for')+"']");
     if(forInput){
       forInput.val(formatedValue);
     }
  });
});
