$(document).ready(function() {
	function add() {
		$(items[i]).addClass('homepage-boxes__item_small');
	}

	var items = $('#catalog-boxes').children('.homepage-boxes__item');
	console.log(items);
	var long1 = 0;
	var long2 = 1;
	var long3 = 2;
	var step = 5; 

	for (var i = 0; i < items.length; i++) {
		switch (i) {
			case long1:
				add();
				long1 += step;
				break;
			case long2:
				add();
				long2 += step;
				break;
			case long3:
				add();
				long3 += step;
				break; 
		}
	}
})