$(function(){

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.cat-nav-slider').slick({
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 8,
		slidesToScroll: 1,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 1000,
		rtl: dirRtlFlag,
		responsive: [{
				breakpoint: 1300,
				settings: {
					slidesToShow: 7,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 840,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 690,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 515,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			} 
			// {
			// 	breakpoint: 340,
			// 	settings: {
			// 		slidesToShow: 1,
			// 		slidesToScroll: 1
			// 	}
			// }
		]
	});

});